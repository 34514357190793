export const menuItems = [
  {
    name: 'pricing',
    label: 'Pricing',
  },
  {
    name: 'contact',
    label: 'Contact',
  },
  {
    name: 'demo',
    label: 'Request Demo',
  },
  {
    name: 'https://blog.stric.co/',
    label: 'Blog',
    isExternal: true,
  },
]

/*
 {
    name: "https://docs.stric.co/",
    label: "Support",
    isExternal: true,
  },
  {
    name: "https://footprint.stric.co/",
    label: "Footprint by Stric",
    isExternal: true,
  },
*/
