import React from 'react'
import { Link } from 'gatsby'

import imgLB from '../../assets/image/stric-main-dark.png'
import imgLW from '../../assets/image/stric-main-white.png'
import imgFS from '../../assets/image/inner-page/png/yellow-footer-shape.png'

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={`bg-dark-cloud pt-13 pt-lg-27 pb-7 dark-mode-texts position-relative ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="row pb-lg-25">
            <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
              <div className="pr-xl-20 mb-11 mb-lg-0 mt-lg-5">
                <div className="brand-logo mb-5">
                  <Link to="/#">
                    <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt=""
                    />
                    <img
                      src={imgLW}
                      alt=""
                      className="dark-version-logo mx-auto mx-0"
                    />
                  </Link>
                </div>
                <p className="font-size-5 mb-0 text-bali-gray pr-sm-10 pr-md-0">
                  We’re an Australian brand monitoring company.
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 offset-xl-1">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Boring stuff
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="/legal/terms"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Terms of Service
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/legal/privacy"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/legal/sla"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          SLA
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      About us
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="/#"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Our Story
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="https://blog.stric.co/"
                          target="_blank"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Blog
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-lg-6 col-md-5 col-xs-8">
                  <div className="mb-10 mb-lg-0 mr-xl-12">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Phishbeat
                    </h4>
                    <p className="font-size-5 mb-0 text-lily-white">
                      Phishbeat is a lightweight beat for generating and
                      monitoring for Phishing/Defamatory Domains. Connects
                      nicely with your Elastic Stack.
                    </p>
                    <div className="mt-7">
                      <Link
                        to="https://github.com/stric-co/phishbeat"
                        target="_blank"
                        className="font-size-5 d-block text-golden-yellow mb-0"
                        href="https://github.com/stric-co/phishbeat"
                      >
                        Get it here.
                      </Link>
                    </div>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-10 border-top">
            <div className="col-lg-6 text-center text-lg-left">
              <div className="copyright">
                <p className="mb-0 font-size-3 text-bali-gray">
                  © Stric® {new Date().getFullYear()}, Adelaide, South
                  Australia. <i>Stric</i> is a registered trademark.
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <div className="footer-right mt-5 mt-lg-0">
                <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end mb-0">
                  <li>
                    <Link
                      to="https://twitter.com/stricco1"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.facebook.com/the.stric.co/"
                      className="text-white gr-hover-text-blue-3 ml-7"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://linkedin.com/company/stric-au/"
                      className="text-white gr-hover-text-blue-3 ml-7"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://github.com/stric-co"
                      className="text-white gr-hover-text-blue-3 ml-7"
                      target="_blank"
                    >
                      <i className="fab fa-github" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-yellow-shape-img">
          <img src={imgFS} alt="" />
        </div>
      </div>
    </>
  )
}

export default Footer
